<template>
	<div>
		<h2>工单描述</h2>
		<div class="Editor editor_wrap">
			<el-upload class="avatar-uploader" :headers="Headers" :action="ActionUrl" :on-success="handleAvatarSuccess">
			</el-upload>
			<quill-editor class="editor ql-editor" content-type="html" theme="snow" ref="myQuillEditor"
				@ready="ready($event)" @text-change="Submitinput" v-model:content="content" :options="editorOption">
			</quill-editor>
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import Quill from "quill";
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import {
		lineHeightStyle
	} from '@/js/lineHeight'
	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'], //加粗，斜体，下划线，删除线
		['blockquote', 'code-block'], //引用，代码块
		[{
			'header': 1
		}, {
			'header': 2
		}], // 标题，键值对的形式；1、2表示字体大小
		[{
			'list': 'ordered'
		}, {
			'list': 'bullet'
		}], //列表
		[{
			'script': 'sub'
		}, {
			'script': 'super'
		}], // 上下标
		// [{
		// 	'indent': '-1'
		// }, {
		// 	'indent': '+1'
		// }], 
		[{
			'direction': 'rtl'
		}], // 文本方向
		[{
			'size': ['small', false, 'large', 'huge']
		}], // 字体大小
		[{
			lineheight: ['initial', '1', '1.5', '1.75', '2', '3', '4', '5']
		}],
		[{
			'header': [1, 2, 3, 4, 5, 6, false]
		}], //几级标题
		[{
			'color': []
		}, {
			'background': []
		}], // 字体颜色，字体背景颜色
		[{
			'font': []
		}], //字体
		[{
			'align': []
		}], //对齐方式
		['clean'], //清除字体样式
		['image'] //上传图片、上传视频
	]

	export default {
		props: ['contentText','title'],
		data() {
			return {
				Headers: {
					authorization: '',
					appid:'',
					nonce:'',
					timestamp:'',
					sign:''
				},
				ActionUrl: '',
				content: '',
				editorOption: {
					placeholder: "请输入工单描述...",
					modules: {
						toolbar: {
							container: toolbarOptions, //工具栏
							handlers: {
								lineheight: (value) => {
									if (value) {
										let quill = this.$refs.myQuillEditor.getQuill();
										quill.format("lineHeight", value)
									}
								},
								image: function(value) {
									if (value) {
										// 调用element的图片上传组件
										document.querySelector('.avatar-uploader input').click()
									} else {
										let quill = this.$refs.myQuillEditor.getQuill();
										quill.format('image', false)
									}
								}
							}
						}
					}
				}
			}
		},
		
		mounted() {
			// 上转图片url
			// 上转图片url
			this.Headers.authorization = sessionStorage.getItem('token')
			let param = JSON.parse(sessionStorage.getItem('param'))
			this.Headers.appid = param.appid
			this.Headers.nonce = param.nonce
			this.Headers.timestamp = param.timestamp
			this.Headers.sign = param.sign
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/System/FormUpload'
			this.Echo()
		},
		methods: {
			// 回显
			Echo() {
				if (this.title == '编辑') {
					let that = this
					setTimeout(function() {
						let quill = that.$refs.myQuillEditor.getQuill();
						let quillEditor = quill.container.querySelector(".ql-editor");
						quillEditor.innerHTML = that.contentText
					}, 500)
				}
			},
			// 监听内容
			Submitinput() {
				this.$emit("Submitinput",this.escapeStringHTML(this.content))
			},
			// 空格转成 &nbsp;
			escapeStringHTML(str) {
				let strs = str.replace(/[^<>]+(?=<)/g, function(match) {
					return match.replace(/\s/g, '&nbsp;');
				});
				return strs;
			},
			// 上转图片
			handleAvatarSuccess(res, file) {
				// 获取富文本组件实例
				let quill = this.$refs.myQuillEditor.getQuill();
				// // 如果上传成功
				if (res) {
					// 获取光标所在位置
					let length = quill.getSelection().index;
					// 插入图片，res为服务器返回的图片链接地址
					quill.insertEmbed(length, 'image', res.data)
					// 调整光标到最后
					quill.setSelection(length + 1)
				} else {
					// 提示信息，需引入Message
					ElMessage({
						message: '图片插入失败',
						type: 'error',
					})
				}

			},
			// 富文本加载
			ready() {
				Quill.register({
					'formats/lineHeight': lineHeightStyle
				}, true)
			}
		}
	}
</script>

<style scoped>
	.Editor {
		margin-top: 20px;
	}

	.Editor ::v-deep .ql-editor {
		min-height: 500px;
	}

	.editor_wrap /deep/ .avatar-uploader {
		display: none;
	}

	.editor_wrap /deep/ .editor {
		line-height: normal !important;
		margin-bottom: 60px;
	}

	.editor_wrap /deep/ .editor .ql-bubble .ql-editor a {
		color: #136ec2;
	}

	.editor_wrap /deep/ .editor img {
		max-width: 720px;
		margin: 10px;
	}

	.editor_wrap /deep/ .ql-snow .ql-color-picker .ql-picker-options {
		padding: 3px 5px;
		width: 192px;
	}

	.editor_wrap /deep/ .ql-snow .ql-tooltip[data-mode="link"]::before {
		content: "请输入链接地址:";
	}

	.editor_wrap /deep/ .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
		border-right: 0px;
		content: "保存";
		padding-right: 0px;
	}

	.editor_wrap /deep/ .ql-snow .ql-tooltip[data-mode="video"]::before {
		content: "请输入视频地址:";
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-size .ql-picker-label::before,
	.editor_wrap /deep/ .ql-snow .ql-picker.ql-size .ql-picker-item::before {
		content: "14px";
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
	.editor_wrap /deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
		content: "10px";
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
	.editor_wrap /deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
		content: "18px";
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
	.editor_wrap /deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
		content: "32px";
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-label::before,
	.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item::before {
		content: "文本";
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
	.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
		content: "标题1";
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
	.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
		content: "标题2";
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
	.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
		content: "标题3";
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
	.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
		content: "标题4";
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
	.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
		content: "标题5";
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
	.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
		content: "标题6";
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label::before,
	.editor_wrap /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item::before {
		content: "标准字体";
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
	.editor_wrap /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
		content: "衬线字体";
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
	.editor_wrap /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
		content: "等宽字体";
	}


	.editor_wrap /deep/ .ql-snow .ql-picker.ql-lineheight .ql-picker-label::before {
		content: '行高';
		font-size: 12px;
		width: 80px;
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1']::before {
		content: '1';
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.5']::before {
		content: '1.5';
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2']::before {
		content: '2';
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='3']::before {
		content: '3';
	}

	.editor_wrap /deep/ .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='4']::before {
		content: '4';
	}
</style>

