import request from './request'

const api = {
	// 列表
	lists: '/Article/index',
	// 新增
	add: '/Article/create',
	// 删除
	dele: '/Article/delete',
	// 详情
	read: '/Article/read',
	// 编辑
	edit: '/Article/update',
}

export {
	request,
	api
}
