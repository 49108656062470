<template>
	<div>
		<div class="dialog">
			<el-form ref="ruleForm" label-width="80px">
				<el-form-item label="排序">
					<span class="xx"></span>
					<el-input clearable v-model="ruleForm.sort" placeholder="请输入排序"></el-input>
				</el-form-item>
				<el-form-item label="名称">
					<span class="xx">*</span>
					<el-input clearable v-model="ruleForm.name" placeholder="请输入名称"></el-input>
				</el-form-item>
			</el-form>
		</div>
		<!-- 工单描述 -->
		<content ref="content" :title="title" :contentText="ruleForm.details" @Submitinput="Submitinput"></content>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import content from './content.vue'
	import {
		request,
		api
	} from "@/api/notice";
	export default {
		props: ['title', 'mid'],
		components: {
			content
		},
		data() {
			return {
				ruleForm: {
					sort: 0,
					name: '',
					details: "",
				}
			}
		},
		
		mounted() {
			if (this.title == '编辑') {
				this.getread()
			}
		},
		methods: {
			// 详情
			Submitinput(e) {
				this.ruleForm.details = e
			},
			// 获取详情
			getread() {
				this.$http.post(api.read, {
						id: this.mid
					})
					.then((res) => {
						if (res.code == 1) {
							this.ruleForm = res.data
						}
					})
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
			},
			//新增/编辑
			SubmitForm() {
				if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入名称',
						type: 'warning',
					})
				} else if (this.ruleForm.details === '') {
					ElMessage({
						message: '请输入内容',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}
				}
			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 1) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('close')
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 1) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.$emit('close')
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>

<style scoped>
</style>
